



































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const ApplicationModule = namespace('ApplicationModule');
import { Application } from '../../store/modules/application/types';
import Icon from '../../components/Icon.vue';
import ApplicationAdd from './ApplicationAdd.vue';

@Component({
    components: {
        Icon,
        ApplicationAdd,
    },
})
export default class ApplicationList extends Vue {
    @ApplicationModule.State
    public applications!: Application[];

    @ApplicationModule.Action
    public load!: () => Promise<Application[]>;

    public showAddApplication = false;
    public placement = 'right';
    public selectedApplicationId = null;
    public mode!: string;

    async mounted() {
        try {
            await this.load();
        } catch (err) {
            console.log(err);
        }
    }

    doShowAddApplication() {
        this.showAddApplication = true;
        this.selectedApplicationId = null;
        this.mode = 'Add Application';
    }

    doHideAddApplication() {
        this.showAddApplication = false;
    }

    viewApplication(applicationId) {
        this.selectedApplicationId = applicationId;
        this.showAddApplication = true;
        this.mode = 'View/Edit Application';
    }

    onSearch(e) {
        console.log(e);
    }

    navigate(to) {
        this.$router.push(to);
    }

    async onAdded(application) {
        this.showAddApplication = false;
        try {
            await this.load();
        } catch (err) {
            console.log(err);
        }
    }

    viewUsers(applicationId) {
        this.$router.push({ path: `/applications/${applicationId}/users` });
    }
}
