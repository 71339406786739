















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const ApplicationModule = namespace('ApplicationModule');
import { Application } from '../../store/modules/application/types';
import Icon from '../../components/Icon.vue';

@Component({
    components: {
        Icon,
    },
})
export default class ApplicationAdd extends Vue {
    public mode = 'add';

    @Prop({ default: '' })
    applicationId!: string;

    @ApplicationModule.State
    public application!: Application;

    @ApplicationModule.Action
    public add!: (application: Application) => Promise<Application>;

    @ApplicationModule.Action
    public retrieve!: (applicationId: string) => Promise<Application>;

    @ApplicationModule.Action
    public updateApplicationById!: ({applicationId: string, application: Application}) => Promise<Application>;

    @ApplicationModule.Action
    public resetApplication!: () => Promise<Application>;

    async created() {
        if (this.applicationId) {
            this.mode = 'edit';
            this.application = await this.retrieve(this.applicationId);
        }
    }

    async publishApplication() {
      console.log(this.mode);
      if (this.mode === 'add') this.saveApplication();
      else this.updateApplication();
    }

    async saveApplication() {
        await this.add(this.application);
        // TODO: comma seperated urls formatting
        this.$emit('onAdded', this.application);
        this.$message.success(
            `Application ${this.application.name} addedd successfully.`,
        );
    }

    async updateApplication() {
        await this.updateApplicationById({applicationId: this.applicationId, application:this.application});
        // TODO: comma seperated urls formatting
        this.$emit('onAdded', this.application);
        this.$message.success(
            `Application ${this.application.name} updated successfully.`,
        );
    }

    @Watch('applicationId')
    async ifApplicationIdProvided(value: string, oldValue: string) {
        if (value) {
            this.mode = 'edit';
            this.application = await this.retrieve(value);
        } else {
            this.mode = 'add';
            this.application = await this.resetApplication();
        }
    }
}
